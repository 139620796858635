import { Controller } from '@hotwired/stimulus';
import Mark from 'mark.js/src/vanilla';

export default class extends Controller {
  connect() {
    this.highlight_all_occurrences();
    this.activate_dropdown();
  }

  // When clicking on a dropdown, show the dropdown and hide all other dropdowns.
  activate_dropdown() {
    this.element.querySelectorAll('[data-target-dropdown]').forEach(element => {
      element.addEventListener('click', function(event) {
        const targetId = this.getAttribute('data-target-dropdown');
        const target = document.getElementById(targetId);
        target.classList.toggle('show');
        event.stopPropagation();
        event.preventDefault();
        document.querySelectorAll('.dropdown--container.show').forEach(dropdown => {
          if (dropdown.id !== targetId) {
            dropdown.classList.remove('show');
          }
        });
      });
    });
  }

  // Highlight all text occurrences of the from the #q element inside the .items element with vanilla JS.
  highlight_all_occurrences() {
    let query = document.querySelector('#q').value;
    if (query && query.length > 0) {
      this.element.querySelectorAll('.name-part, .ref-part-content, .product-list-item, .col-content-item').forEach(element => {
        new Mark(element).mark(query);
      });
    }
  }
}
