import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['list']
  connect() {}

  toggle(){
    if (this.element.classList.contains('collapsed')) {
      this.expand()
    } else {
      this.collapse()
    }

  }
  collapse(){
    this.element.classList.add('collapsed')
    this.element.classList.remove('expanded')
  }

  expand(){
    this.element.classList.add('expanded')
    this.element.classList.remove('collapsed')
  }

  toggle_more_less(){
    if (this.listTarget.classList.contains('show-less')) {
      this.show_more()
    } else {
      this.show_less()
    }
  }

  show_more(){
    this.listTarget.classList.add('show-more')
    this.listTarget.classList.remove('show-less')
  }

  show_less(){
    this.listTarget.classList.add('show-less')
    this.listTarget.classList.remove('show-more')
  }

  disconnect() {}
}
