import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static values = {
    type: String,
    id: String
  };

  connect() {}

  remove() {
    let filter_type = this.typeValue;
    let filter_id = this.idValue;
    if (filter_type === 'daterange') {
      this.resetDateRangePicker();
    } else if (filter_type === 'search_query') {
      this.resetSearchFilter();
    } else {
      let select_elm = $(`select[name="${filter_type}[]"]`);
      let input_elm = $(`input[name="${filter_type}[${filter_id}]"]`);
      if (select_elm.length) {
        $(`[name="${filter_type}[]"]`).find(`option[value="${filter_id}"]`).prop('selected', false).trigger('change');
      } else {
        input_elm.prop('checked', false).trigger('change');
      }
    }
    $(this)
      .parent('.active_filter_item')
      .fadeOut(300, function () {
        $(this).remove();
      });
    $(this.element).closest('form')[0].requestSubmit();
  }

  resetSearchFilter() {
    $('input[name="q"]').val('').trigger('change');
  }

  resetDateRangePicker() {
    this.dispatch('resetDateRangePicker', {bubbles: true, cancelable: false});
  }
}
