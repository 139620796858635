import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['email', 'form', 'authFrom', 'submit', 'password', 'ssoMessage', 'forgot', 'forceNormalLogin', 'defaultLoginMessage'];

  connect() {
    this.lastRequest = null;
    this.checkEmail();
  }

  checkEmail(e) {
    if(this.emailTarget.value != '') {
      this.submitTarget.classList.remove('o-button--disabled');

      var url = window.location.protocol + '//' + window.location.host + window.location.pathname +
      '?user[email]=' + this.emailTarget.value + this.append_auth_from_ipad();

      if(this.lastRequest != null) {
        this.lastRequest.abort();
      }

      this.lastRequest = jQuery.get(url, function(data) {
        if(data.redirect_to) {
          this.formTarget.action = data.redirect_to;
          this.hidePassword();
          this.showSsoMessage();
          this.setSsoButtonText();
          this.hideForgotLink();
          this.showDefaultLoginMessageMessage();
        } else {
          this.formTarget.action = '/users/sign_in';
          this.showPassword();
          this.hideSsoMessage();
          this.setLoginButtonText();
          this.showForgotLink();
          this.hideDefaultLoginMessageMessage();
        }
      }.bind(this), 'json');

    } else {
      this.submitTarget.classList.add('o-button--disabled');
    }
  }

  hidePassword(){
    this.passwordTarget.style.display = 'none';
  }

  showPassword() {
    this.passwordTarget.style.display = 'block';
  }

  hideSsoMessage(){
    this.ssoMessageTarget.style.display = 'none';
  }

  showSsoMessage() {
    this.ssoMessageTarget.style.display = 'block';
  }

  hideDefaultLoginMessageMessage(){
    this.defaultLoginMessageTarget.style.display = 'none';
  }

  showDefaultLoginMessageMessage() {
    this.defaultLoginMessageTarget.style.display = 'block';
  }

  setLoginButtonText(){
    this.submitTarget.innerHTML = 'Sign in';
  }

  setSsoButtonText() {
    this.submitTarget.innerHTML = 'Continue';
  }

  hideForgotLink(){
    this.forgotTarget.style.display = 'none';
  }

  showForgotLink() {
    this.forgotTarget.style.display = 'block';
  }

  append_auth_from_ipad() {
    if ( this.hasAuthFromTarget && this.authFromTarget.value == 'ipad' ) {
      return '&auth_from=ipad';
    } else {
      return '';
    }
  }
}
