import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'authFrom', 'email', 'title'];

  connect() {
    var url = window.location.protocol + '//' + window.location.host + window.location.pathname +
        '?user[email]=' + this.emailTarget.value + '&auth_from=ipad';

    jQuery.get(url, function(data) {
      if(data.redirect_to) {
        this.formTarget.action = data.redirect_to;
        this.formTarget.submit();
      } else {
        this.titleTarget.innerHTML = 'Something went wrong.<br />Please close this window.'
      }
    }.bind(this), 'json');
  }
}
