import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {}

  showDropdown(e) {
    if (this.isTouchDevice()) {
      const navItem = e.target.closest('.c-navigation__item');
      // prevent default click event unless it contains class "hover"
      if (!navItem.classList.contains('hover')) {
        e.preventDefault();
        // Get the first parent occurance of e.target that has class .c-navigation__item
        navItem.classList.add('hover');
      }
    }
  }

  hideDropdown(e){
    if (this.isTouchDevice()) {
      // If the event target does not have class .c-navigation__item or is a child of that class, remove the class .hover from it
      if (!e.target.closest('.c-navigation__item')) {
        // find all .c-navigation__item and remove class .hover
        document.querySelectorAll('.c-navigation__item').forEach(navItem => {
          navItem.classList.remove('hover');
        });
      }
    }
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  disconnect() {}
}
