import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default class extends Controller {
  static values = {
    nested: Boolean,
    disabled: Boolean,
    options: Object
  };

  connect() {
    if (this.disabledValue) {
      return;
    }

    this.instances = [];

    if (this.nestedValue) {
      this.initializeNestedTooltips();
    } else {
      this.replaceTitleWithTippyContent(this.element);
      this.instances.push(
        tippy(this.element, {
          ...this.defaultOptions(),
        })
      );
    }
  }

  initializeNestedTooltips() {
    const tooltips = this.element.querySelectorAll('[title]');
    tooltips.forEach((tooltip) => {
      this.replaceTitleWithTippyContent(tooltip);
      this.instances.push(
        tippy(tooltip, {
          ...this.defaultOptions()
        })
      );
    });
  }

  replaceTitleWithTippyContent(element) {
    const title = element.getAttribute('title');
    if (title) {
      element.setAttribute('data-tippy-content', title);
      element.removeAttribute('title');
    }
  }

  defaultOptions() {
    return {
      ...{
        placement: "right",
        arrow: true,
        allowHTML: true,
      },
      ...this.optionsValue
    }
  }

  disconnect() {
    this.instances.forEach((instance) => instance.destroy());
  }
}
