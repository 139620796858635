import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    returnUrl: String,
  }

  static targets = ['closeButton'];
  connect() {
    let turboModal = this
    // Listen to turbo before turbo:before-fetch-request event and set to loading
    // After the fetch is done, the loading will be removed
    this.element.addEventListener('turbo:before-fetch-request', function(){
      turboModal.add_loading();
    })
    this.element.addEventListener('turbo:before-frame-render', function(){
      turboModal.remove_loading();
    })
  }

  add_loading() {
    this.element.classList.add('loading');
  }

  remove_loading() {
    this.element.classList.remove('loading');
  }

  disconnect() {
    let turboModal = this
    this.element.removeEventListener('turbo:before-fetch-request', function(){
      turboModal.add_loading();
    })
    this.element.removeEventListener('turbo:before-fetch-response', function(){
      turboModal.remove_loading();
    })
  }
}
