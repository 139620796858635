import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['pageInput', 'perPageInput'];
  static values = { total: Number };
  connect() {

  }

  currentPage() {
    return parseInt(this.pageInputTarget.value);
  }
  next() {
    this.pageInputTarget.value = this.currentPage() + 1;
    this.pageInputTarget.form.requestSubmit();
  }

  previous() {
    this.pageInputTarget.value = this.currentPage() - 1;
    this.pageInputTarget.form.requestSubmit();
  }

  disconnect() {

  }
}
